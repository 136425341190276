import React, { useState } from "react"
// import { navigate } from "gatsby"

import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Button from "react-bootstrap/Button"

const FormCallback = ({ slug, title, price }) => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    setValidated(true)
  }
  return (
    <Form
      data-netlify="true"
      netlify-honeypot="bot-field"
      action="/dyakuemo/?form_type=callback"
      method="POST"
      name={`callback-${slug}`}
      form-name={`callback-${slug}`}
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      id="collbackModalForm"
      // className="container"
    >
      <Form.Group hidden>
        <input type="hidden" name="form-name" value={`callback-${slug}`} />
        <Form.Label>
          Для незаповнення <Form.Control type="text" name="bot-field" />
        </Form.Label>
        <Form.Label>
          Сторінка{" "}
          <Form.Control
            type="hidden"
            placeholder="Сторінка"
            name="page"
            value={title}
          />
        </Form.Label>
        <Form.Label>
          Ціна{" "}
          <Form.Control
            type="hidden"
            placeholder="Ціна"
            name="price"
            value={price}
          />
        </Form.Label>
      </Form.Group>

      <Form.Row className="align-items-center mb-3">
        <Col xs="auto">
          <Form.Label htmlFor="inlineFormInputGroup" srOnly>
            Ваш номер телефону
          </Form.Label>
          <InputGroup className="mb-3" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <span role="img" aria-label="Emoji:call_me_hand">
                  🤙🏻
                </span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="inlineFormInputGroup"
              name="phone"
              placeholder="номер телефону"
              required
            />
          </InputGroup>
          {/* <Form.Control.Feedback type="invalid">
              вкажіть номер, будь ласка
            </Form.Control.Feedback> */}
          <Button type="submit" variant="danger" size="lg" block>
            Замовити
          </Button>
        </Col>
      </Form.Row>
      {/* <p>З 10:00 до 18:00 телефонуємо за 15 хвилин</p> */}
    </Form>
  )
}

FormCallback.defaultProps = {
  slug: `/`,
  title: `empty title`,
  price: `0`,
}

export default FormCallback

import React, { useState } from "react"

import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import {
  // RiUserLine,
  // RiAccountCircleLine,
  RiAccountPinCircleLine,
} from "react-icons/ri"

const CabinetLoginModal = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <div
        onClick={handleShow}
        onKeyDown={handleShow}
        role="button"
        tabIndex={0}
        className="ml-auto d-flex align-items-center"
        title="Особистий кабінет"
      >
        {/* <span className="top-menu-icon-bg">
          <RiAccountPinCircleLine className="top-menu-icon" />
        </span> */}
        <RiAccountPinCircleLine className="top-menu-icon" />
        Вхід в кабінет
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal-cabinet-login"
        size="md"
        centered
      >
        <Modal.Header className="modal-header-newtele text-center" closeButton>
          <Modal.Title className="w-100">Вхід в особистий кабінет</Modal.Title>
        </Modal.Header>

        <Form
          id="newteleloginform"
          name="form_login"
          method="post"
          action="https://bill.newtele.com.ua:9443/"
          className="top-menu-login-form mb-5 mx-5 mt-3"
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Control type="text" placeholder="Ваш логін" name="user" />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Control type="password" placeholder="Пароль" name="passwd" />
          </Form.Group>
          <Button
            variant="newtele"
            type="submit"
            name="logined"
            className="mb-3"
            block
          >
            Увійти
          </Button>
        </Form>
      </Modal>
    </>
  )
}

export default CabinetLoginModal

import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

const FormConnect = ({
  pageSlug,
  pageTitle,
  productTitle,
  productPrice,
  formType,
}) => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    setValidated(true)
  }

  return (
    <Form
      data-netlify="true"
      netlify-honeypot="bot-field"
      action={`/dyakuemo/?form_type=${formType}`}
      method="POST"
      name="connect-form"
      form-name="connect-form"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      id="connectForm"
      className="mx-4 mb-4"
    >
      <Form.Group hidden>
        <input
          type="hidden"
          name="form-name"
          value="connect-form"
          // id="noFillField"
        />
        <Form.Label>
          Поле для незаполнения <Form.Control type="text" name="bot-field" />
        </Form.Label>
        <Form.Label>
          Сторінка
          <Form.Control
            type="hidden"
            name="page"
            placeholder="Сторінка"
            value={pageTitle}
            // id="PageField"
          />
        </Form.Label>
        <Form.Label>
          Продукт:
          <Form.Control
            type="hidden"
            name="product"
            placeholder="Продукт"
            value={`${productTitle} - ${productPrice} грн`}
            // id="PageField"
          />
        </Form.Label>
      </Form.Group>

      <Form.Group controlId="formPhone">
        <Form.Label>Телефон для зв`язку *</Form.Label>
        <Form.Control
          type="tel"
          name="tel"
          placeholder="+380..."
          // id="PhoneField"
          required
        />
        {/* <Form.Text className="text-muted">
          Ми зв'яжемося з вами найближчим часом
        </Form.Text> */}
        <Form.Control.Feedback type="invalid">
          вкажіть номер, будь ласка
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formName">
        <Form.Label>Ім`я</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="вкажіть ваше ім`я"
          // id="NameField"
        />
      </Form.Group>

      <Form.Group controlId="formCheckbox">
        <Form.Check
          type="checkbox"
          name="agree"
          label="згода з правилами сайту"
          id="AgreeField"
          required
        />
        <Form.Control.Feedback type="invalid">
          прийміть, будь ласка, правила сайту *
        </Form.Control.Feedback>
      </Form.Group>
      <Button type="submit" variant="danger" size="lg" className="mt-4" block>
        Відправити
      </Button>
    </Form>
  )
}

FormConnect.defaultProps = {
  pageSlug: `/`,
  pageTitle: `Новое подключение`,
  productTitle: `не товар`,
  productPrice: `0`,
  formType: `connect`,
}

export default FormConnect

import React from "react"
import { Link } from "gatsby"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
// import Button from "react-bootstrap/Button"
// import Form from 'react-bootstrap/Form'

import CabinetLoginModal from "../components/global/CabinetLoginModal"
import ModalButtonConnect from "../components/global/ModalConnect"
// import CabinetLogin from "../components/global/CabinetLogin"

// import { MdPhone } from "react-icons/md"
import { Ri24HoursFill } from "react-icons/ri"
// import { MdAttachMoney } from "react-icons/md"

const Navigation = ({ pageSlug, pageTitle }) => (
  <>
    <Navbar variant="light" className="top-menu">
      <Navbar.Text className="" expand="lg">
        <a
          href="tel:+380442774277"
          className="top-menu-button d-flex align-items-center"
        >
          {/* <span className="top-menu-icon-bg">
            <Ri24HoursFill className="top-menu-icon" />
          </span> */}
          <Ri24HoursFill className="top-menu-icon" />
          (044) 277-4-277
        </a>
        {/* <Link to="/sposoby-oplaty/" className="top-menu-button">
          <span className="top-menu-icon-bg">
            <MdAttachMoney className="top-menu-icon" />
          </span>
          Способи оплати
        </Link> */}
      </Navbar.Text>
      <CabinetLoginModal />
    </Navbar>

    <Navbar bg="none" variant="light" expand="lg" className="py-3 menu-bar">
      <Link to="/" className="navbar-brand brand newtele-brandname">
        NewTelecom
      </Link>
      {/* <Link to="/" className="navbar-brand brand">
        <span className="logo-new">New</span>
        <span className="logo-telecom">Telecom</span>
      </Link> */}
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto main-menu">
          <NavDropdown title="Інтернет" id="nav-dropdown-internet" as="div">
            <NavDropdown.Item
              as={Link}
              to="/internet-v-budynok/"
              activeClassName="active"
            >
              Інтернет в будинок
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/internet-v-kvartyru/"
              activeClassName="active"
            >
              Інтернет в квартиру
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/internet-v-ofis/"
              activeClassName="active"
            >
              Інтернет для бізнеса
            </NavDropdown.Item>
          </NavDropdown>

          {/* Телебачення */}

          <NavDropdown title="Телебачення" id="nav-dropdown-tv">
            <NavDropdown.Item
              as={Link}
              to="/telebachennya/"
              activeClassName="active"
            >
              Інтернет-телебачення
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/telebachennya/trinity-tv/"
              activeClassName="active"
            >
              Телебачення Trinity-tv
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/telebachennya/sweet-tv/"
              activeClassName="active"
            >
              Телебачення Sweet.tv
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/telebachennya/megogo/"
              activeClassName="active"
            >
              Телебачення Megogo
            </NavDropdown.Item>
            {/* <NavDropdown.Item
              as={Link}
              to="/telebachennya/oll-tv/"
              activeClassName="active"
            >
              Телебачення OLL.TV
            </NavDropdown.Item> */}
          </NavDropdown>

          {/* Обладнання */}
          <Nav.Link as={Link} to="/shop/" activeClassName="active">
            Обладнання
          </Nav.Link>

          {/* Абонентам */}
          <NavDropdown title="Абонентам" id="nav-dropdown-customers">
            <NavDropdown.Item as={Link} to="/promo/" activeClassName="active">
              Акції
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/sposoby-oplaty/"
              activeClassName="active"
            >
              Способи оплати
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/kontakty/" activeClassName="active">
            Контакти
          </Nav.Link>
          <Nav.Item>
            {/* <Button
              variant="outline-danger"
              className="ml-lg-1 ml-sm-0 text-uppercase d-none d-sm-block"
            >
              Підключитись
            </Button> */}
            <ModalButtonConnect pageSlug={pageSlug} pageTitle={pageTitle} />
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
)

Navigation.defaultProps = {
  pageSlug: `/`,
  pageTitle: `Заголовок`,
  productTitle: `не товар`,
  productPrice: `0`,
}

export default Navigation

import React from "react"
import Navigation from "../Navbar"
import CallUs from "../CallUs"
import Footer from "../Footer"
// import Form1 from "../global/FormCallback"
// import Form2 from "../global/FormConnect"

export default function BasicLayout({ children }) {
  return (
    <>
      <Navigation />
      {/* <Form1 /> */}
      {/* <Form2 /> */}
      {children}
      <CallUs />
      <Footer />
    </>
  )
}

import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import BackgroundImage from "gatsby-background-image"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

// import { VscCallIncoming } from "react-icons/vsc"

import FormCallback from "./FormCallback"

const ModalCallback = ({ buttonVariant, buttonText, buttonClass, props }) => {
  const [show, setShow] = React.useState(false)
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleToggle = () => setShow(!show)

  return (
    <StaticQuery
      query={graphql`
        query {
          background: file(relativePath: { eq: "call.jpeg" }) {
            relativePath
            id
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // Set ImageData.
        // const imageData = [
        //   data.background.childImageSharp.fluid,
        //   `linear-gradient(
        //     25deg,
        //     rgba(128,0,128,0.4),
        //     rgba(35,65,97,0.9) 60%)`,
        // ].reverse()

        return (
          <>
            <Button
              onClick={handleToggle}
              size="lg"
              variant="danger"
              className="text-uppercase my-3"
            >
              <span className="callback-btn">
                {/* <VscCallIncoming className="mr-1 callback-btn-icon" /> */}
                Зателефонувати вам?
                <span role="img" aria-label="Emoji:call_me_hand">
                  🤙🏻
                </span>
              </span>
            </Button>

            <Modal
              {...props}
              show={show}
              onHide={() => setShow(false)}
              aria-labelledby="callbackModal"
              className="clearfix"
              centered
            >
              {/* <BackgroundImage fluid={imageData}> */}
              <Modal.Header closeButton>
                <Modal.Title id="callbackModal" className="mx-auto">
                  Зворотній дзвінок
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="callback-modal-body mx-auto">
                <p>Вкажіть ваш номер і ми зателефонуємо</p>
                <FormCallback className="callback-form" />
              </Modal.Body>
              {/* </BackgroundImage> */}
            </Modal>
          </>
        )
      }}
    />
  )
}

ModalCallback.defaultProps = {
  buttonText: `Консультація`,
  buttonVariant: `link`,
  buttonClass: ``,
}

export default ModalCallback

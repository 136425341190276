import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { SiFacebook } from "react-icons/si"
import { FcGoogle } from "react-icons/fc"

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulCity(
          sort: { fields: cityName, order: ASC }
          filter: { node_locale: { eq: "uk" }, isPublished: { eq: true } }
        ) {
          nodes {
            cityName
            id
            cityImage {
              fixed {
                ...GatsbyContentfulFixed_withWebp
              }
            }
            seo {
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <footer className="py-5 footer-bg">
          <Container className="my-5">
            <Row>
              <Col lg={3} md={6} sm={12}>
                <div className="h5 mb-4">Розділи</div>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/"
                    >
                      Головна
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/internet-v-kvartyru/"
                    >
                      Інтернет в квартиру
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/internet-v-budynok/"
                    >
                      Інтернет в будинок
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/internet-v-ofis/"
                    >
                      Інтернет для бізнесу
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/telebachennya/"
                    >
                      Телебачення
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/shop/"
                    >
                      Обладнання
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/kontakty/"
                    >
                      Контакти
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/blog/"
                    >
                      Блог
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/blog/vakansiyi/"
                    >
                      Вакансії
                    </Link>
                  </li>
                </ul>
                <div className="h5 mt-5 mb-3">Допомога</div>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/promo/"
                    >
                      Акції
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/blog/bystryi-wifi-internet/"
                    >
                      Вимірювання швидкості
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-white"
                      activeClassName="active"
                      to="/sposoby-oplaty/"
                    >
                      Оплата послуг
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col lg={6} md={12} sm={12}>
                <div className="h5 mb-4 mt-5 mt-md-0">Покриття</div>
                <ul className="list-unstyled footer-coverage">
                  {data.allContentfulCity.nodes.map(city => (
                    <li key={city.id}>
                      <Link
                        to={`/internet-${city.seo.slug}/`}
                        className="text-white"
                      >
                        {city.cityName}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>

              <Col lg={3} md={6} sm={12}>
                <div>
                  <div className=" h5 mb-4 mt-5 mt-md-0">
                    ТОВ «Новий Телеком»
                  </div>
                  <p>
                    Гореничі,
                    <br />
                    Бучанський район,
                    <br />
                    вул. Столична 1 А
                    <br />
                    Код ЄДРПОУ: 37866847
                    <br />
                    ІПН:378668410130
                  </p>
                  <ul className="list-unstyled mt-3">
                    <li className="mb-3">
                      <Link
                        className="text-white "
                        activeClassName="active"
                        to="/publichniy-dohovir-oferta/"
                      >
                        Публічний договір (Оферта)
                      </Link>
                    </li>
                    <li>support@newtele.com.ua</li>
                    <li>
                      <a href="tel:+380442774277" className="text-white">
                        +38(044) 277-4-277
                      </a>{" "}
                      – цілодобово
                    </li>

                    <li className="footer-social-link mt-4">
                      <a
                        href="https://www.facebook.com/newtelecomua"
                        className="text-white footer-social-icon"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <SiFacebook />
                      </a>
                    </li>
                    <li className="footer-social-link ml-3">
                      <a
                        href="https://g.page/newtelecomua"
                        className="text-white footer-social-icon"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {/* <SiGooglemybusiness /> */}
                        <FcGoogle />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        <Container fluid className="py-3 developer">
          <Row className="text-center">
            <Col className="">
              розробка і підтримка{" "}
              <a
                href="https://koval.dev/?utm_source=newtelecom&utm_medium=referral&utm_campaign=devlink"
                title="підтримка і розробка сайту"
                rel="nofollow noopener noreferrer"
                target="_blank"
                className="devname"
              >
                koval.dev
              </a>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default Footer

import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
// import Button from "react-bootstrap/Button"
// import { FiPhone } from "react-icons/fi"

import ModalCallback from "../components/global/ModalCallback"

const CallUs = () => {
  const { callimg } = useStaticQuery(
    graphql`
      {
        callimg: contentfulAsset(
          id: { eq: "0491ac7c-2138-51fe-b644-c895e4cdfcc0" }
        ) {
          fluid(
            maxWidth: 1200
            maxHeight: 600
            resizingBehavior: FILL
            cropFocus: FACE
            quality: 100
          ) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
    `
  )

  const bgImage = [
    callimg.fluid,
    `radial-gradient(at 50% 100%, rgba(15, 1, 94, 0.54), rgba(81, 45, 168, 0.7))`,
  ].reverse()

  return (
    <>
      <BackgroundImage
        Tag="section"
        fluid={bgImage}
        className="contact-us py-5"
      >
        <Container className="my-5 text-center">
          <div className="h3">Цілодобова підтримка</div>
          <a href="tel:+380442774277" className="contact-phone">
            +38(044) 277-4-277
          </a>
          <ModalCallback />
        </Container>
      </BackgroundImage>
    </>
  )
}

export default CallUs
